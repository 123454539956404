import useOnlineStatus from 'hooks/useOnlineStatus';
import NetworkErrorComponent from 'pages/Errorpages/NetworkErrorComponent';
import React, { memo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getToken } from 'utils/utils';

const PrivateRoute = ({ component: Component }) => {

    const location = useLocation();

    const isOnline = useOnlineStatus()
    return (
        getToken() 
            ? (isOnline ? Component : <NetworkErrorComponent /> ) 
            : <Navigate to={`/login?redirectTo=${encodeURIComponent(location.pathname)}`} />
    );
}

export default memo(PrivateRoute);
