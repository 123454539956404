import React from "react";

const NetworkErrorComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#ffffff",
        color: "#721c24",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <h1 style={{ fontSize: "2.5rem", marginBottom: "1rem" }}>
        No Internet Connection
      </h1>
      <p style={{ fontSize: "1.2rem", maxWidth: "600px" }}>
        Please check your network settings and try again.
      </p>
    </div>
  );
};

export default NetworkErrorComponent;
