import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { ASSIGN_PROJECT_MEMBERS, PROJECT_ROLES, REMOVE_PROJECT_MEMBERS, UPDATE_PROJECT_MEMBER_ROLE, USERS } from "services/endPoints";
import { privateRequest } from "services/privateRequest";
import { getProjectId } from "utils/utils";

const initialState = {
  projectsMemberList: [],
  projectsRoleList: [],
  allProjectsMemberList: [],
  memberListDropDown: false,
  loading : false,
  totalCount : 0
};

export const getProjectsMemberList = createAsyncThunk(
  "entity/getProjectsMemberList",
  async ({ projectId }, {signal}) => {
    if(projectId) {
      const res = await privateRequest.get(
        `/api/user-projects/projects/${projectId}/users`
      , {signal});
      return {
        data: res?.data?.data || [],
      };
    }
  }
);

export const getProjectsMemberListPage = createAsyncThunk(
  "entity/getProjectsMemberListPage",
  async ({ projectId, pageNo = 1, pageSize = 10 }) => {
    if(projectId) {
      const res = await privateRequest.get(
        `/api/user-projects/projects/${projectId}/users?pageNo=${pageNo}&pageSize=${pageSize}`
      );
      return {
        data: res?.data?.data || [],
      };
    }
  }
);

export const getAllProjectsMemberList = createAsyncThunk(
    "entity/getAllProjectsMemberList",
    async () => {
      const res = await privateRequest.get(USERS);
      return {
        data: res?.data?.data || [],
      };
    }
  );

export const getProjectsRoleList = createAsyncThunk(
  "entity/getProjectsRoleList",
  async (params) => {
    const res = await privateRequest.get(PROJECT_ROLES, { params });
    return {
      data: res?.data?.data || [],
    };
  }
);

export const assignProjectMember = createAsyncThunk(
    "entity/assignProjectMember",
    async (payload, {dispatch}) => {
      const res = await privateRequest.post(ASSIGN_PROJECT_MEMBERS, payload);
      toast.success(res.data.message)
      dispatch(getAllProjectsMemberList())
      dispatch(getProjectsMemberList({ projectId: getProjectId() }));
      return {
        data: res?.data?.data || [],
      };
    }
  );

  export const updateProjectMemberRole = createAsyncThunk(
    "entity/updateProjectMemberRole",
    async (payload, { dispatch }) => {
      try {
        const res = await privateRequest.put(
          UPDATE_PROJECT_MEMBER_ROLE,
          payload
        );
        toast.success(res.data.message)
        dispatch(getAllProjectsMemberList());
        dispatch(getProjectsMemberList({ projectId: getProjectId() }));
        return {
          data: res?.data?.data || [],
        };
      } catch (error) {

      }
    }
  );

  export const removeProjectMember = createAsyncThunk(
    "entity/removeProjectMember",
    async (payload, { dispatch }) => {
      try {
        const res = await privateRequest.delete(REMOVE_PROJECT_MEMBERS, {
          params: { userIds: payload, "project-id": getProjectId() },
        });
        toast.success(res.data.message);
        dispatch(getAllProjectsMemberList());
        dispatch(getProjectsMemberList({ projectId: getProjectId() }));
      } catch (error) {
        toast.error(error.message || "Error while removing member");
      }
    }
  );

const assignMemberSlice = createSlice({
  name: "assignMember",
  initialState,
  reducers: {
    setMemberListDropDown : (state, action)=>{
      state.memberListDropDown = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectsMemberList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectsMemberList.fulfilled, (state, action) => {
        state.projectsMemberList = action.payload.data;
        state.totalCount = action?.payload?.data?.length;
        state.loading = false;
      })
      .addCase(getProjectsMemberList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProjectsMemberListPage.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectsMemberListPage.fulfilled, (state, action) => {
        state.projectsMemberList = action.payload?.data?.data;
        state.totalCount = action.payload?.data?.count?.total;
        state.loading = false;
      })
      .addCase(getProjectsMemberListPage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateProjectMemberRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProjectMemberRole.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProjectMemberRole.rejected, (state) => {
        state.loading = false;
      })
      .addCase(removeProjectMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeProjectMember.fulfilled, (state, action) => {
        state.loading = false;
        state.totalCount = state.allProjectsMemberList?.length
      })
      .addCase(removeProjectMember.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProjectsRoleList.fulfilled, (state, action) => {
        state.projectsRoleList = action.payload.data;
      })
      .addCase(getAllProjectsMemberList.fulfilled, (state, action) => {
        state.allProjectsMemberList = action.payload.data;
      });
  },
});

export default assignMemberSlice.reducer;
export const { setMemberListDropDown } = assignMemberSlice.actions;
