import useOnlineStatus from 'hooks/useOnlineStatus';
import NetworkErrorComponent from 'pages/Errorpages/NetworkErrorComponent';
import React, { memo } from 'react';
import { Navigate} from 'react-router-dom';
import { PATH } from 'utils/pagePath';
import { getToken } from 'utils/utils';

const PublicRoute = ({ component: Component }) => {
    const isOnline = useOnlineStatus()
    return (
        !getToken() ?(isOnline ? Component : <NetworkErrorComponent /> ) : <Navigate to={PATH.PROJECTS} /> 
    )
}
 
export default memo(PublicRoute);
