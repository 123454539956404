import axios from "axios";
import toast from "react-hot-toast";
import { getToken } from "utils/utils";
import { REFRESH_TOKEN } from "./endPoints";
import { publicRequest } from "./publicRequest";

export const privateRequest = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const requestHandler = (request) => {
  const token = getToken() || "";
  request.headers.Authorization = `Bearer ${token}`;
  request.headers["x-user-role"] = localStorage.getItem("currentRole");
  request.headers["project-id"] = localStorage.getItem("projectId");
  return request;
};

const clearToken = () => {
  localStorage.removeItem("token");
};

// Define the structure of a retry queue item
const refreshAndRetryQueue = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const handleRefreshToken = async (error) => {
  const refreshToken = localStorage.getItem("refreshToken");
  const originalRequest = error.config;
  if (!isRefreshing && !originalRequest._retry) {
    originalRequest._retry = true;
    isRefreshing = true;
    try {
      const response = await publicRequest.post(REFRESH_TOKEN, null, {
        params: { refreshToken },
      });
      const { access } = response.data.data;
      localStorage.setItem("token", access);
      originalRequest.headers["Authorization"] = `Bearer ${access}`;


      refreshAndRetryQueue.forEach(({ config, resolve }) => {
        config.headers["Authorization"] = `Bearer ${access}`;
        resolve(privateRequest(config));
      });

      refreshAndRetryQueue.length = 0; // Clear the queue
      return privateRequest(originalRequest);
    } catch (refreshError) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = "/";
    } finally {
      isRefreshing = false;
    }
  }
  return new Promise((resolve, reject) => {
    refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
  });
};

const responseErrorHandler = (error) => {
  if (error.response) {
    const { status, data, message } = error.response;

    switch (status) {
      case 401:
        clearToken();
        return handleRefreshToken(error).then((response) => {
          return response;
        })
          .catch((refreshError) => {
            window.location.href = "/";
          });;
      case 400:
        toast.error(
          data.message
            ? data.message
            : message || "Invalid Value/ Bad Request"
        );
        break;
      case 403:
        toast.error(
          data.message ? data.message : message || "Access Denied/ Forbidden"
        );
        window.location.href = "/errorPage/403";
        break;
      case 404:
        // toast.error(data.message ? data.message : message || "Item doesn't exist")
        break;
      case 405:
        toast.error(data.message ? data.message : message || "Invalid Request");
        break;
      case 409:
        toast.error(data.message ? data.message : message || "Resource already exists.");
        break;
      case 422:
        toast.error(data.message ? data.message : message || "Already Exists");
        break;
        case 500:
        toast.error(data.message ? data.message : message || "Internal Server Error");
        break;
      case 501:
        toast.error(data.message ? data.message : message || "Session Expired");
        window.location.href = "/errorPage/501";
        break;
      case 504:
        toast.error(data.message ? data.message : message || "Network Error");
        window.location.href = "/errorPage/504";
        break;
      default:
        toast.error(
          data.message ? data.message : message || "Some Error Occurred"
        );
        window.location.href = "/errorPage/default";
        break;
    }
  } else {
    if (error.name !== 'CanceledError') toast.error(error?.message || "Some Error Occurred");
  }
  return Promise.reject(error);
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

privateRequest.interceptors.request.use(requestHandler, errorHandler);

privateRequest.interceptors.response.use((response) => {
  return response;
}, responseErrorHandler);
