import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { privateRequest } from 'services/privateRequest';
import { GET_TEAMS, CREATE_TEAMS } from 'services/endPoints';
import toast from 'react-hot-toast';

export const createTeam = createAsyncThunk('team/createTeam', async (teamData) => {
  try {
    const response = await privateRequest.post(CREATE_TEAMS, teamData);
    toast.success(response?.data?.message || "Team Created Successfully")
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

export const getTeamById = createAsyncThunk('team/getTeamById', async (id) => {
  try {
    const response = await privateRequest.get(`${GET_TEAMS}/${id}`);
    return response.data.data;
  } catch (error) {
    throw error.response.data;
  }
});


export const fetchTeams = createAsyncThunk('team/fetchTeams', async ({ pageNumber, pageSize }) => {
  try {
    const response = await privateRequest.get(`${GET_TEAMS}?page-no=${pageNumber}&page-size=${pageSize}`);
    return response?.data?.data || [];
  } catch (error) {
    throw error.response.data;
  }
});


export const updateTeam = createAsyncThunk('team/updateTeam', async ({ id, updatedData }) => {
  try {
    const response = await privateRequest.put(`${GET_TEAMS}/${id}`, updatedData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

export const deleteTeam = createAsyncThunk('team/deleteTeam', async (id) => {
  try {
    const response = await privateRequest.delete(`${GET_TEAMS}/${id}`);
    return { id };
  } catch (error) {
    throw error.response.data;
  }
});

export const updateTeamMember = createAsyncThunk('team/deleteTeam', async (id) => {
  try {
    const response = await privateRequest.delete(`${GET_TEAMS}/${id}`);
    return { id };
  } catch (error) {
    throw error.response.data;
  }
});



const initialState = {
  team:{},
  teamLoader:false,
  teams: [],
  loading: false,
  error: null,
  count: {},
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getTeamById.pending, (state, action) => {
        state.teamLoader = true;
        state.error = null;
      })
      .addCase(getTeamById.fulfilled, (state, action) => {
        state.teamLoader = false;
        state.team = action.payload;
      })
      .addCase(getTeamById.rejected, (state, action) => {
        state.teamLoader = false;
        state.error = action.error.message;
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        state.loading = false;

        if (Array.isArray(state.teams)) {
          state.teams.push(action.payload);
        } else {
          state.teams = [action.payload];
        }
      })
      .addCase(createTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchTeams.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.loading = false;
        state.teams = action.payload;
        state.count = action.payload?.count;
      })
      .addCase(fetchTeams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTeam.fulfilled, (state, action) => {
        state.loading = false;
        const updatedTeam = action.payload;

        if (Array.isArray(state.teams)) {
          const index = state.teams.findIndex((team) => team?.id === updatedTeam?.id);
          if (index !== -1) {
            state.teams[index] = updatedTeam;
          }
        }
      })
      .addCase(updateTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message;
      })

      .addCase(deleteTeam?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTeam?.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(state.teams)) {
          state.teams = state.teams.filter((team) => team?.id !== action?.payload?.id);
        } else {
          state.teams = [];
        }
      })
      .addCase(deleteTeam?.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default teamSlice.reducer;