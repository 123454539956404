import { Button, ClickAwayListener, Tooltip } from '@mui/material'
import classNames from 'classnames'
import React, { memo } from 'react'
import styles from './Button.module.scss'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyButton = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
       // title="Add"
        slotProps={{
          popper: {
            disablePortal: true,
          },
        }}
      >
        <Button
          {...props}
          size='small'
          variant={props?.variant ?? "contained"}
          className={classNames(styles.CopyButton, props?.className)}
          onClick={()=>{
            props.onClick()
            handleTooltipOpen()
          }}
        >
          {props?.children}
          <ContentCopyIcon sx={{height: '20px'}}/>

        </Button>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default memo(CopyButton)